import { SectionContentProps, TemplateElement } from "../../types";

const SectionContent: TemplateElement<SectionContentProps> = ({
  children,
  className,
}) => {
  return <div className={"max-w-7xl px-5 m-auto " + className}>{children}</div>;
};

export default SectionContent;
