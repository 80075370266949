const AlertTriangle = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99975 7.50001V10.8333M9.99975 14.1667H10.0081M8.84585 3.24311L1.99178 15.082C1.61161 15.7386 1.42153 16.0669 1.44962 16.3364C1.47412 16.5715 1.59727 16.785 1.7884 16.924C2.00753 17.0833 2.38691 17.0833 3.14568 17.0833H16.8538C17.6126 17.0833 17.992 17.0833 18.2111 16.924C18.4022 16.785 18.5254 16.5715 18.5499 16.3364C18.578 16.0669 18.3879 15.7386 18.0077 15.082L11.1537 3.24311C10.7748 2.58881 10.5854 2.26166 10.3383 2.15178C10.1228 2.05593 9.87672 2.05593 9.66117 2.15178C9.41406 2.26166 9.22466 2.58881 8.84585 3.24311Z"
      stroke="#DC6803"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { AlertTriangle };
