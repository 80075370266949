import React from "react";
import Button, { ButtonProps } from "./Button";
import { ButtonRequiredColorsType, darkenHex } from "./ButtonPalette";

interface CustomButtonProps extends ButtonProps {
  customDefault: ButtonRequiredColorsType;
}

const CustomButton = ({
  customDefault,
  customHover,
  ...props
}: CustomButtonProps) => {
  const hoverColor = customHover || {
    backgroundColor: darkenHex(customDefault.backgroundColor, 0.05),
    color: customDefault.color,
    borderColor: customDefault.borderColor,
  };
  return (
    <Button
      customDefault={customDefault}
      customHover={hoverColor}
      customDisabled={customDefault}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export { CustomButton };
