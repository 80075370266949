import {
  motion,
  HTMLMotionProps,
  MotionProps,
  Variants,
  AnimationProps,
} from "framer-motion";

export interface MotionElementWrapperProps
  extends HTMLMotionProps<"div">,
    MotionProps {
  children: React.ReactNode;
  duration?: number;
  ease?: string;
  variants?: Variants;
  className?: string;
  animate?: AnimationProps["animate"];
  initial?: AnimationProps["initial"];
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const MotionElementWrapper: React.FC<MotionElementWrapperProps> = ({
  children,
  duration = 0.2,
  ease = "easeOut",
  variants,
  className,
  fullWidth = false,
  fullHeight = false,
  ...motionProps
}) => (
  <motion.div
    {...motionProps}
    variants={variants}
    transition={{ duration, ease, ...motionProps.transition }}
    className={`${fullWidth ? "w-full" : ""} ${
      fullHeight ? "h-full" : ""
    } ${className}`}
  >
    {children}
  </motion.div>
);

export default MotionElementWrapper;
