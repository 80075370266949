export const ShoppingBagDefault = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1737 11.8713L4.89453 5.83301H15.4162C15.9587 5.83301 16.3562 6.34217 16.2245 6.86884L15.1012 11.3622C14.9304 12.0447 14.3504 12.5463 13.6504 12.6163L7.97036 13.1847C7.1237 13.2688 6.34953 12.703 6.1737 11.8713Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.89533 5.83301L4.35366 3.33301H2.91699"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2578 16.056C14.0894 16.056 13.9528 16.1927 13.9544 16.361C13.9544 16.5293 14.0911 16.666 14.2594 16.666C14.4278 16.666 14.5644 16.5293 14.5644 16.361C14.5636 16.1927 14.4269 16.056 14.2578 16.056"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.24746 16.0556C7.07912 16.0556 6.94246 16.1923 6.94412 16.3606C6.94246 16.5298 7.07996 16.6664 7.24829 16.6664C7.41662 16.6664 7.55329 16.5298 7.55329 16.3614C7.55329 16.1923 7.41662 16.0556 7.24746 16.0556"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
