"use client";

import styled, { css } from "styled-components";
import { themeColor } from "../styles-utils";
import { ColorType } from "../styles-utils/themeColor";
import { SpaceAfter, SpacingValues } from "../globals/types";
import { StoreTemplate } from "../../templates/TemplateLoader";
import SectionContent from "../../templates/default/elements/SectionContent";

// Singlecolumn flex

/*
┌──┬────────┬──┐
│  │  x x x │  │
│  │        │  │
│  │  x x x │  │
│  │        │  │
│  │  x x x │  │
└──┴────────┴──┘
*/

export const FlexLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

interface SectionProps extends SpaceAfter {
  children?: React.ReactNode;
  className?: string;
  background?: ColorType;
  boxShadow?: boolean;
}

export const Section: React.FC<SectionProps> = ({
  children,
  className,
  background,
  spaceAfter,
  boxShadow,
}) => {
  const Template = StoreTemplate.get();
  // for not found or expired stores, fallback to SectionContent from default template
  const Component = Template?.SectionContent || SectionContent;

  return (
    <SectionContainer
      background={background}
      spaceAfter={spaceAfter}
      boxShadow={boxShadow}
    >
      <Component className={className}>{children}</Component>
    </SectionContainer>
  );
};

const SectionContainer = styled.section<
  SpaceAfter & {
    background?: ColorType;
    boxShadow?: boolean;
  }
>`
  background: ${({ background, theme }) =>
    themeColor(background!)({ theme }) || "transparent"};
  padding-bottom: ${({ spaceAfter }) =>
    !spaceAfter || spaceAfter === "none" ? 0 : SpacingValues[spaceAfter]};
  ${({ boxShadow }) =>
    boxShadow &&
    css`
      box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.special.border};
    `};
`;
